import React, { FC, ReactNode } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";

interface CodeBlockProps {
  children: { props: { children: ReactNode[] } };
  language: string;
}

// Recursively parse the children of the code block to get the code string from the object
// This object is coming from the gatsby-remark-prismjs plugin
function parseCodeString(children: any) {
  if (Array.isArray(children.props.children)) {
    return children.props.children
      .map((child: any) =>
        typeof child === "string" ? child : parseCodeString(child)
      )
      .join("");
  } else {
    return typeof children.props.children === "string"
      ? children.props.children
      : parseCodeString(children.props.children);
  }
}

const CodeBlock: FC<CodeBlockProps> = ({ children, language }) => {
  const codeString = parseCodeString(children);

  return (
    <div className="relative">
      <div className="absolute right-0 top-0 bg-gray-500 text-white px-2 py-1 text-xs uppercase">
        {language}
      </div>
      <SyntaxHighlighter
        language={language}
        style={tomorrow}
        // Styling for the code block container
        className="pt-8 text-base font-mono overflow-x-auto"
        useInlineStyles={false}
      >
        {codeString}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeBlock;
