import React, { FC, useEffect, useState } from "react";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import Layout from "../components/core/Layout";
import { removeItemFromArray } from "../utils/ArrayUtils";
import { navigate } from "gatsby";
import Cancel from "@material-ui/icons/CancelOutlined";
import PageTitle from "../components/core/PageTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import DonateRow from "../components/articles/DonateRow";
import CodeBlock from "./CodeBlock";

interface StringKeyArray {
  [key: string]: any[];
}

interface StringKey {
  [key: string]: string;
}

interface DeclarativeMapperPageProps {
  pageContext: {
    frameworks: {
      concepts: any[];
      metadata: any[];
      JetpackCompose: any[];
      Flutter: any[];
      React: any[];
      SwiftUI: any[];
      VueJS: any[];
    };
    frameworksMdx: any[];
    comparison: string[];
  };
}

const gridCols: StringKey = {
  "0": "grid-cols-10",
  "1": "grid-cols-10",
  "2": "grid-cols-10",
  "3": "grid-cols-11",
  "4": "grid-cols-9",
};

const keyColSpan: StringKey = {
  "0": "xs:col-span-10 lg:col-span-3",
  "1": "xs:col-span-10 lg:col-span-3",
  "2": "xs:col-span-10 lg:col-span-2",
  "3": "xs:col-span-11 lg:col-span-2",
  "4": "xs:col-span-9 lg:col-span-1",
};

const itemColSpan: StringKey = {
  "0": "xs:col-span-10 lg:col-span-7",
  "1": "xs:col-span-10 lg:col-span-7",
  "2": "xs:col-span-10 lg:col-span-4",
  "3": "xs:col-span-11 lg:col-span-3",
  "4": "xs:col-span-9 lg:col-span-2",
};

function frameworkToDisplayName(framework: string): string {
  if (framework == "JetpackCompose") {
    return "Jetpack Compose";
  } else if (framework == "VueJS") {
    return "Vue.js";
  } else {
    return framework;
  }
}

function getComparisonPath(permutation: string[]) {
  let path = "";
  for (let i = 0; i < permutation.length; i++) {
    path += permutation[i];
    if (i != permutation.length - 1) {
      path += " vs ";
    }
  }
  return path;
}

function getUrlPath(permutation: string[]) {
  let path = "";
  for (let i = 0; i < permutation.length; i++) {
    path += permutation[i];
    if (i != permutation.length - 1) {
      path += "-vs-";
    }
  }
  return path;
}

export default function DeclarativeMapperPage(
  props: DeclarativeMapperPageProps
) {
  let [frameworksList, setFrameworksList] = React.useState(
    props.pageContext.comparison
  );
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const displaySnackbar = () => {
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = (event: any) => {
    if (event instanceof TouchEvent || event instanceof PointerEvent) {
      return;
    }
    setShowSnackbar(false);
  };

  const frameworkData: StringKeyArray = {
    concepts: props.pageContext.frameworks.concepts,
    metadata: props.pageContext.frameworks.metadata,
  };

  const components = {
    pre: CodeBlock,
  };

  function navigateToUpdatedScreen(frameworks: string[]) {
    const path =
      "/compare-declarative-frameworks/" +
      (frameworks.length > 1 ? frameworks.join("-vs-") : frameworks[0] || "");
    navigate(path);
  }

  return (
    <Layout
      maxWidth="xl"
      pageTitle={
        props.pageContext.comparison.length >= 1
          ? `${getComparisonPath(
              props.pageContext.comparison
            )} - Compare Declarative UI Frameworks`
          : "Compare Declarative UI Frameworks"
      }
      pageDescription={
        props.pageContext.comparison.length >= 1
          ? `${getComparisonPath(
              props.pageContext.comparison
            )} - see how these declarative frameworks compare on various concepts like creating a content, prop drilling, state management, conditional rendering, slot api's etc.`
          : "Compare declarative frameworks like Jetpack Compose, Flutter, SwiftUI, React and Vue.js. See how they compare on various concepts like creating a content, prop drilling, state management, conditional rendering, slot api's etc."
      }
      pageSlug={
        props.pageContext.comparison.length == 0
          ? "/compare-declarative-frameworks"
          : `/compare-declarative-frameworks/${getUrlPath(
              props.pageContext.comparison
            )}`
      }
      pageImageUrl="/compare-frameworks.png"
      seoImageUrl="/compare-frameworks.png"
    >
      <div className="w-full px-4 pb-16">
        <PageTitle
          header="Compare Declarative Frameworks"
          subheader="Choose up-to 3 frameworks and learn how they compare to each other."
        />
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="error">
            You can only compare up-to 3 frameworks at a time.
          </Alert>
        </Snackbar>
        <div className="w-full flex justify-center items-center mb-12">
          {frameworkData.metadata.map((meta) => {
            const isFrameworkSelected = frameworksList.includes(meta.id);
            return (
              <div
                className={`flex-row flex justify-center items-center mr-2 rounded-full py-2 px-4 border-2 hover:border-slate-300 hover:bg-slate-100 hover:cursor-pointer ${
                  isFrameworkSelected ? "!bg-slate-200 !border-[#222]" : ""
                }`}
                onClick={() => {
                  if (frameworksList.includes(meta.id)) {
                    const frameworkListCopy = Object.assign([], frameworksList);
                    const updatedList = removeItemFromArray(
                      frameworkListCopy,
                      meta.id
                    );
                    navigateToUpdatedScreen(updatedList);
                  } else if (frameworksList.length <= 2) {
                    const updatedList = [...frameworksList, meta.id];
                    navigateToUpdatedScreen(updatedList);
                  } else if (frameworksList.length == 3) {
                    displaySnackbar();
                  }
                }}
              >
                <GatsbyImage
                  image={getImage(meta.logo) as IGatsbyImageData}
                  alt="Framework Logo"
                  className="w-6 h-6"
                  objectFit="contain"
                />
                <div className="text-base text-black pl-2 hidden lg:block">
                  {frameworkToDisplayName(meta.id)}
                </div>
                <Cancel
                  className={`w-5 h-5 ml-1 ${
                    isFrameworkSelected ? "block" : "invisible lg:hidden"
                  }`}
                />
              </div>
            );
          })}
        </div>
        <div className={`grid ${gridCols[frameworksList.length]}`}>
          <div
            className={`text-xl font-inter font-normal p-4 hidden lg:block w-full bg-[#4636f7] text-white ${
              keyColSpan[frameworksList.length]
            }`}
          >
            Concept
          </div>
          {frameworksList.length == 0 ? (
            <div className={`p-4 ${itemColSpan[frameworksList.length]}`}></div>
          ) : null}
          {frameworksList.map((framework) => {
            return (
              <div
                className={`text-xl font-inter font-normal text-center p-4 hidden lg:block bg-[#4636f7] text-white ${
                  itemColSpan[frameworksList.length]
                }`}
              >
                {/* <span className={`rounded-full border-2 px-3 py-1`}> */}
                <span className="">{frameworkToDisplayName(framework)}</span>
              </div>
            );
          })}

          {props.pageContext.frameworks.concepts.map((concept, index) => {
            return (
              <>
                <div
                  className={`${
                    keyColSpan[frameworksList.length]
                  } px-4 lg:py-8 py-4 w-full ${
                    index % 2 === 0 ? "" : "bg-indigo-50"
                  }`}
                >
                  <div className="text-xl font-inter">{concept.title}</div>
                  <div className="text-lg font-nunitosans mt-4">
                    {concept.description}
                  </div>
                </div>
                {frameworksList.length == 0 ? (
                  <div
                    className={`px-4 lg:py-8 py-4 ${
                      itemColSpan[frameworksList.length]
                    }`}
                  >
                    {index == 0 ? (
                      <div className="flex justify-center items-center flex-col">
                        <div className="font-inter text-xl font-bold">
                          Pick a framework from the list above
                        </div>{" "}
                        <StaticImage
                          src="../images/empty_list.png"
                          className=""
                          alt="Pick a framework from the list above"
                          placeholder="blurred"
                          transformOptions={{ fit: "inside" }}
                          width={250}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : null}
                {frameworksList.map((framework) => {
                  const frameworkMdxNode =
                    props.pageContext.frameworksMdx.filter((item) => {
                      return (
                        concept.id === item.frontmatter.id &&
                        framework === item.frontmatter.framework
                      );
                    })[0];
                  return (
                    <div
                      className={`px-4 lg:py-8 py-4 ${
                        itemColSpan[frameworksList.length]
                      } ${index % 2 === 0 ? "" : "bg-indigo-50"}`}
                    >
                      <div className="font-inter text-base font-bold visible lg:hidden pb-4">
                        {frameworkToDisplayName(framework)}
                      </div>
                      {frameworkMdxNode ? (
                        <article className="prose prose-slate prose-pre:rounded-2xl prose-pre:!my-0">
                          {/* Define the ThemedCodeBlock here to access framework */}
                          {(() => {
                            const ThemedCodeBlock: FC = (props) => (
                              <CodeBlock
                                {...props}
                                language={frameworkMdxNode.frontmatter.language}
                              />
                            );
                            const components = { pre: ThemedCodeBlock };

                            return (
                              <MDXProvider components={components}>
                                <MDXRenderer
                                  frontmatter={frameworkMdxNode.frontmatter}
                                >
                                  {frameworkMdxNode.body}
                                </MDXRenderer>
                              </MDXProvider>
                            );
                          })()}
                        </article>
                      ) : null}
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>
        <div className="mt-16">
          <DonateRow />
        </div>
      </div>
    </Layout>
  );
}
